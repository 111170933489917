import React, { useEffect, useState } from 'react'
import { AiFillAudio, AiTwotoneAudio } from 'react-icons/ai'
import useMediaRecorder from '@wmik/use-media-recorder';
import { useLongPress, LongPressDetectEvents } from "use-long-press";

import { isIOS } from '../../utils/browser.utils';
import './styles.css'

function RecordingAudio({ setAudio, audio, uploadAudio }) {
  const [isRecording, setIsRecording] = useState(false)
  const [wasCancelled, setWasCancelled] = useState(false)
  const ios = isIOS()

  const {
    status,
    mediaBlob,
    stopRecording,
    startRecording
  } = useMediaRecorder({
    blobOptions: { type: 'audio/wav' }, // ios ? 'audio/mpeg; codecs=mp3' : 'audio/wav' },
    mediaStreamConstraints: { audio: true },
  });

  const handleRecordAudio = () => {
    setAudio(null)
    setIsRecording(true)
    startRecording()
  }

  const handleStopRecordingAudio = (cancelled = false) => {
    setIsRecording(false)
    setWasCancelled(cancelled)
    stopRecording()
  }

  useEffect(() => {
    if (status === 'stopped' && mediaBlob)// && !wasCancelled) 
    {
      setAudio(mediaBlob)

      if (audio !== mediaBlob && mediaBlob) {
        uploadAudio(mediaBlob, ios)
      }
    }
  }, [status, mediaBlob, wasCancelled]);

  const callback = React.useCallback(() => {
  }, []);

  const bind = useLongPress(callback, {
    onStart: (event, meta) => handleRecordAudio(),
    onFinish: (event, meta) => handleStopRecordingAudio(),
    onCancel: (event, meta) => handleStopRecordingAudio(true),
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH
  });

  return (
    <div
      //{...bind()}
      className="btn-record"
      onClick={!isRecording ? handleRecordAudio : handleStopRecordingAudio}
    >
      {!isRecording ?
        <AiFillAudio />
        :
        <AiTwotoneAudio
          className='recording_blink'
        />
      }
    </div>
  )
}

export default RecordingAudio