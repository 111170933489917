import { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google'
import BottomMenu from './containers/bottom-menu';
// import HomePage from './components/HomePage';
// import LibraryPage from './components/LibraryPage';
// import ProfilePage from './components/ProfilePage';
import Topics from './modules/topics';
import Messages from './components/messages';

import './App.css';

function App() {
  const [user, setUser] = useState({})
  const [userPhoto, setUserPhoto] = useState({})
  const [pageInfo] = useState([
    {
      id: 1,
      title: 'The essentials',
      img: 'info_essentials.png',
      btn_title: 'Check in',
      isActive: true,
      lesson: 'Lesson 1',
      lesson_passage: '30',
    },
    {
      id: 2,
      title: 'The city',
      img: 'info_city.png',
      btn_title: 'Indications',
      isActive: false,
      lesson: 'Lesson 2',
      lesson_passage: '45',
    },
    {
      id: 3,
      title: 'The room',
      img: 'info_room.png',
      btn_title: 'Amenities',
      isActive: false,
      lesson: 'Lesson 3',
      lesson_passage: '45',
    },
    {
      id: 4,
      title: 'The spa',
      img: 'info_spa.png',
      btn_title: 'The spa',
      isActive: false,
      lesson: 'Lesson 4',
      lesson_passage: '55',
    },
    {
      id: 5,
      title: 'The essentials',
      img: 'info_essen.png',
      btn_title: 'Check out',
      isActive: false,
      lesson: 'Lesson 1',
      lesson_passage: '60',
    },
  ])
  const [topicId, setTopicId] = useState({})
  const [topicName, setTopicName] = useState("")
  const [token, setToken] = useState('')
  const [greeting, setGreeting] = useState(true)

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      localStorage.setItem('access_token', '')
    }
  }, [])

  const onSuccess = (credentialResponse) => {
    localStorage.setItem('access_token', credentialResponse.access_token)
    setToken(localStorage.getItem('access_token'))
    loadTopics(localStorage.getItem('access_token'))
    loadUserInfo(localStorage.getItem('access_token'))
    loadUserPhoto(localStorage.getItem('access_token'))
  }

  const login = useGoogleLogin({
    onSuccess: onSuccess,
    onError: (err) => { console.log('login fail', err); },
    flow: 'implicit',
  });

  async function loadUserInfo(token) {
    let response = await fetch('https://people.googleapis.com/v1/people/me?personFields=names', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    let user = await response.json()
    localStorage.setItem('user_name', user.names[0].displayName)
    setUser(user.names[0].displayName)
    return user
  }

  async function loadUserPhoto(token) {
    let response = await fetch('https://people.googleapis.com/v1/people/me?personFields=photos', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    let userPhoto = await response.json()
    localStorage.setItem('user_photo', userPhoto.photos[0].url)
    setUserPhoto(userPhoto.photos[0].url)
    return userPhoto
  }

  const [topics, setTopics] = useState([])
  const baseUrl = process.env.REACT_APP_BASE_URL
  const url = `${baseUrl}/api/topics/`

  async function loadTopics(token) {
    let response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    if (!response.ok) {
      localStorage.setItem('access_token', '')
    }
    let json = await response.json()
    setTopics(json.topics)
    return json;
  }


  if (!localStorage.getItem('access_token') && localStorage.getItem('access_token') === '') {

    return (
      <div className="App">

        {/* {  !localStorage.getItem('access_token') && localStorage.getItem('access_token') === ''  ?  */}
        <div id='App_greetings'>
          <img className='logo' src='./img/airlingo_logo.png' alt='logo'></img>
          <p className='companion'>Your AI training companion</p>
          <div id='signInDiv'>
            <Link to='/' className='login_btn' onClick={() => {
              login()
            }}>
              Login
            </Link>
          </div>
          <p className='or '>or</p>
          <a className='create_account' href='  ' >Create an account</a>
        </div>
      </div>
    )
  }
  // :
  else if (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== '' && Object.keys(topics) !== 0 && topics) {

    return (
      <div className="App">
        <Routes>
          <Route path='/' element={<BottomMenu />}>
            <Route
              path='/'
              element={
                <Topics
                  info={pageInfo}
                  topics={topics}
                  setTopicId={setTopicId}
                  loadTopics={loadTopics}
                  setUserPhoto={setUserPhoto}
                  setUser={setUser}
                  setTopicName={setTopicName}
                />} />
            <Route path='/messages'
              element={<Messages
                token={token}
                user={user}
                userPhoto={userPhoto}
                info={pageInfo}
                setGreeting={setGreeting}
                greeting={greeting}
                topics={topics}
                setTopics={setTopics}
                loadTopics={loadTopics}
                topicId={topicId}
                topicName={topicName}
                setTopicId={setTopicId} />} />
          </Route>
        </Routes>
      </div>
    )
  }
}

export default App;
